import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";
import TableFiles from "./ListFilesTypeAndSubTypes";
import Loading from "../../../others/LoadingFull";

const ListAndFiltersType = ({ type, subType }) => {
  const [removeLoading, setRemoveLoading] = useState(false);
  const [resultfile, setResultFile] = useState([]);
  const [cnpj, setCNPJ] = useState("");

  useEffect(() => {
    if (type && subType) {
      const fetchData = async () => {
        try {
          const res = await api.post(
            `/atosAdministrativos/list-by-type-and-subtype`,
            { type, subType }
          );
          if (!res.data.err) {
            const result = res.data.res;
            if (result.length > 0) {
              const firstItem = result[0];
              if (firstItem.CNPJ) {
                const stringCnpj = firstItem.CNPJ.replace(/\D/g, "");
                setCNPJ(stringCnpj);
              }
              const formattedResult = formatString(
                result,
                Object.keys(firstItem)
              );
              setResultFile(formattedResult);
            }
          }
        } catch (err) {
          console.log(err);
        } finally {
          setRemoveLoading(true);
        }
      };

      fetchData();
    }
  }, [type, subType]);
  const formatString = (data, keys) => {
    const resultString = data.map((item) => {
      const formattedItem = {};
      keys.forEach((key) => {
        let value = item[key];
        if (key.toUpperCase().includes("DATA")) {
          value = dateRender(value);
        }
        if (key.toUpperCase().includes("CNPJ")) {
          value = value
            ? value.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              )
            : "";
        }
        if (typeof value !== "string" && key.toUpperCase().includes("VALOR")) {
          value = moneyFrom(value);
        } else if (typeof value !== "string") {
          value = value ? value.toString() : "";
        }
        formattedItem[key] = value;
      });
      return formattedItem;
    });
    return resultString;
  };

  const dateRender = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("pt-BR");
  };

  const moneyFrom = (money) => {
    if (typeof money !== "string") return "";
    const format = { minimumFractionDigits: 2, currency: "BRL" };
    return parseFloat(money).toLocaleString("pt-BR", format);
  };

  return (
    <>
      {!removeLoading && <Loading />}
      {resultfile.length > 0 && (
        <TableFiles files={resultfile} cnpj={cnpj} name={"Alterar-Detalhes"} />
      )}
    </>
  );
};

export default ListAndFiltersType;
